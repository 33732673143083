import { useFormContext as useReactHookFormContext } from "react-hook-form";

import { Wrapper } from "../../../components/index.js";
import TextArea, { type TextAreaProps } from "../../../components/TextArea/TextArea.js";
import { useFieldDefaultValue } from "../../hooks/useFieldDefaultValue.js";
import type CommonFieldOptions from "../../models/CommonFieldOptions.js";
import type { InputWrapperProps } from "./InputWrapper.js";
import InputWrapper from "./InputWrapper.js";

type Props = CommonFieldOptions &
  Omit<TextAreaProps, "defaultValue"> & {
    name: string;
    defaultValue?: unknown;
  };

const TextAreaInput = ({
  className,
  inputClassName,
  name,
  placeholder,
  required,
  disabled,
  hidden,
  autoFocus,
  readOnly,
  inputStyle = "NORMAL",
  defaultValue,
  rows = 5,
  value,
  onChange,
  ...otherProps
}: Props & InputWrapperProps) => {
  const formDefaultValue = useFieldDefaultValue(name);
  const methods = useReactHookFormContext();
  const register = methods.register(name);

  return (
    <Wrapper
      condition={inputStyle !== "CHROMELESS"}
      wrapper={(c) => (
        <InputWrapper {...otherProps} htmlFor={name}>
          {c}
        </InputWrapper>
      )}
    >
      <TextArea
        rows={rows}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        hidden={hidden}
        required={required}
        defaultValue={defaultValue || formDefaultValue}
        key={formDefaultValue ?? defaultValue}
        disabled={disabled}
        readOnly={readOnly}
        inputStyle={inputStyle}
        id={name}
        {...register}
        onChange={(e) => {
          void register.onChange(e);
          onChange?.(e);
        }}
        value={value}
        placeholder={placeholder}
        className={className}
        inputClassName={inputClassName}
      />
    </Wrapper>
  );
};

export default TextAreaInput;
